import { Department } from '@tremaze/shared/feature/department/types';
import { Division } from '@tremaze/shared/feature/division/types';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { User, UserTypeName } from '@tremaze/shared/feature/user/types';
import { Pagination } from '@tremaze/shared/models';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  CaseFixtures,
  DepartmentFixtures,
  DivisionFixtures,
  InstitutionFixtures,
  LocationFixtures,
  UserFixtures,
} from '@tremaze/fixtures';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import {
  AssignmentAction,
  AssignmentTarget,
  SuggestionConfig,
  SuggestionsDataSource,
} from './data-source';
import { TremazeLocation } from '@tremaze/location-types';
import { Case } from '@tremaze/case-types';

const _options: DataSourceMethodsPaginatedOptions = {
  filter: {
    page: 0,
    pageSize: 50,
  },
};

@Injectable()
export class MockSuggestionsDataSource extends SuggestionsDataSource {
  getDepartments(
    target: AssignmentTarget,
    action: AssignmentAction,
    filter?: string | undefined,
  ): Observable<Department[]> {
    return of(DepartmentFixtures.generateDepartmentPage(_options).content);
  }

  getInstitutions(
    target: AssignmentTarget,
    action: AssignmentAction,
    config?: SuggestionConfig,
  ): Observable<Institution[]> {
    return of(InstitutionFixtures.generateInstitutionPage(_options).content);
  }

  getDivisions(
    target: Omit<AssignmentTarget, 'division'>,
    action: AssignmentAction,
    config?: SuggestionConfig,
  ): Observable<Division[]> {
    return of(DivisionFixtures.generateDivisionPage(_options).content);
  }

  getUsers(
    target: AssignmentTarget,
    action: AssignmentAction,
    filter?: string | undefined,
    userTypes?: UserTypeName[] | undefined,
  ): Observable<User[]> {
    return of(UserFixtures.generateUserPage(_options).content);
  }

  getCases(
    target: AssignmentTarget,
    action: AssignmentAction,
    filter?: string,
    instIds?: string[],
    userIds?: string[],
  ): Observable<Pagination<Case>> {
    return of(CaseFixtures.generateCasePage(_options));
  }

  getEventResourceViewUserSuggestions(
    config?:
      | ({ userId?: string | undefined; filterValue?: string | undefined } & {
          page?: number | undefined;
          pageSize?: number | undefined;
        })
      | undefined,
  ): Observable<Pagination<User>> {
    return of(UserFixtures.generateUserPage(_options));
  }

  getManagedAccountsSuggestions(config?: SuggestionConfig): Observable<User[]> {
    return of(UserFixtures.generateUserPage(_options).content);
  }

  getLocations(): Observable<TremazeLocation[]> {
    return of(LocationFixtures.generateLocationPage(_options).content);
  }
}

export const provideMockSuggestionsDataSource = () => ({
  provide: SuggestionsDataSource,
  useClass: MockSuggestionsDataSource,
});
