import { Injectable } from '@angular/core';
import { CaseDataSource, CaseFolderViewDataSource } from './case-data-source';
import { Case, CaseStatisticIdentifier, CaseStatus } from '@tremaze/case-types';
import { Pagination } from '@tremaze/shared/models';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { delay, Observable, of } from 'rxjs';
import {
  ApprovalFixtures,
  CaseFixtures,
  CaseStatisticIdentifierFixtures,
  CaseStatusFixtures,
  DepartmentFixtures,
  EventFixtures,
  UserFixtures,
} from '@tremaze/fixtures';
import { User } from '@tremaze/shared/feature/user/types';
import { Approval } from '@tremaze/shared/feature/approval/types';
import { CustomFormFillOutResult } from '@tremaze/shared/feature/custom-forms/feature/fill-out';
import { CustomFormSubmission } from '@tremaze/shared/feature/custom-forms/types';
import { CaseInput } from './types';
import { FolderViewDataSourceMock } from '@tremaze/shared/feature/file-storage/data-access';
import { Department } from '@tremaze/shared/feature/department/types';
import { TremazeDate } from '@tremaze/shared/util-date';
import { TremazeEvent } from '@tremaze/shared/feature/event/types';

@Injectable()
export class CaseDataSourceMock implements CaseDataSource {
  getPaginated(
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<Case>> {
    return of(CaseFixtures.generateCasePage(options));
  }

  delete(id: string): Observable<void> {
    return of(undefined);
  }

  getFreshById(id: string): Observable<Required<Case>> {
    return of(CaseFixtures.generateCase(1) as Required<Case>);
  }

  create(input: CaseInput): Observable<Case> {
    return of(CaseFixtures.generateCase(1)).pipe(delay(1000));
  }

  update(id: string, input: CaseInput): Observable<Case> {
    return of(CaseFixtures.generateCase(1)).pipe(delay(1000));
  }

  addApprovalToCase(caseId: string, approvalId: string): Observable<void> {
    return of(undefined);
  }

  getAvailableCaseStatusesForInstitution(): Observable<Pagination<CaseStatus>> {
    return of(
      CaseStatusFixtures.generateCaseStatusPage({
        filter: {
          page: 0,
          // chose 6 since that's the amount of example status names
          pageSize: 6,
        },
      }),
    );
  }

  getCaseStatuses(
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<CaseStatus>> {
    return this.getAvailableCaseStatusesForInstitution();
  }

  getAvailableStatisticsIdentifiersForInstitution(
    institutionId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<CaseStatisticIdentifier>> {
    return of(
      CaseStatisticIdentifierFixtures.generateCaseStatisticIdentifierPage(
        options,
      ),
    );
  }

  getAvailableClientsForInstitution(filterValue: string): Observable<User[]> {
    return of(
      UserFixtures.generateUserPage({ filter: { page: 0, pageSize: 20 } })
        .content,
    );
  }

  getAvailableEmployeesForInstitution(filterValue: string): Observable<User[]> {
    return of(
      UserFixtures.generateUserPage({ filter: { page: 0, pageSize: 20 } })
        .content,
    );
  }

  getAvailableApprovals(
    institutionId: string,
    clientId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<Approval>> {
    return of(ApprovalFixtures.generateApprovalPage(options));
  }

  getAvailableDepartmentsForInstitution(
    institutionId: string,
    filterValue: string,
  ): Observable<Department[]> {
    return of(
      DepartmentFixtures.generateDepartmentPage({
        filter: {
          page: 0,
          pageSize: 20,
        },
      }).content,
    );
  }

  getCustomFormSubmissionsForCase(
    caseId: string,
  ): Observable<CustomFormSubmission[]> {
    return of([]);
  }

  submitCustomFormForCase(
    caseId: string,
    value: CustomFormFillOutResult,
  ): Observable<void> {
    return of(undefined);
  }

  getCaseStatisticIdentifierById(
    id: string,
  ): Observable<CaseStatisticIdentifier> {
    return of(
      CaseStatisticIdentifierFixtures.generateCaseStatisticIdentifier(1),
    );
  }

  getCaseStatusById(id: string): Observable<CaseStatus> {
    return of(CaseStatusFixtures.generateCaseStatus(1));
  }

  createCaseStatisticIdentifierForInstitution(
    institutionId: string,
    input: Partial<CaseStatisticIdentifier>,
  ): Observable<CaseStatisticIdentifier> {
    return of(
      CaseStatisticIdentifierFixtures.generateCaseStatisticIdentifier(1),
    );
  }

  createCaseStatusForInstitution(
    institutionId: string,
    input: Partial<CaseStatus>,
  ): Observable<CaseStatus> {
    return of(CaseStatusFixtures.generateCaseStatus(1));
  }

  updateCaseStatisticIdentifier(
    id: string,
    input: Partial<CaseStatisticIdentifier>,
  ): Observable<CaseStatisticIdentifier> {
    return of(
      CaseStatisticIdentifierFixtures.generateCaseStatisticIdentifier(1),
    );
  }

  updateCaseStatus(
    id: string,
    input: Partial<CaseStatus>,
  ): Observable<CaseStatus> {
    return of(CaseStatusFixtures.generateCaseStatus(1));
  }

  deleteCaseStatisticIdentifier(id: string): Observable<void> {
    return of(undefined);
  }

  deleteCaseStatus(id: string): Observable<void> {
    return of(undefined);
  }

  getHasCaseNumberRangeSettingForInstitution(): Observable<boolean> {
    return of(false);
  }

  getEventsForCase(
    caseId: string,
    startDate: TremazeDate,
    endDate: TremazeDate,
  ): Observable<TremazeEvent[]> {
    return of(
      EventFixtures.generateEventPage({ filter: { page: 0, pageSize: 20 } })
        .content,
    );
  }
}

@Injectable()
export class CaseFolderViewDataSourceMock
  extends FolderViewDataSourceMock
  implements CaseFolderViewDataSource
{
  setCaseId(caseId: string) {
    // noop
  }
}

export const provideMockCaseDataSources = () => [
  {
    provide: CaseFolderViewDataSource,
    useClass: CaseFolderViewDataSourceMock,
  },
  {
    provide: CaseDataSource,
    useClass: CaseDataSourceMock,
  },
];
