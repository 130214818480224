import {
  Component,
  DoCheck,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject, Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { FormControl } from '@ngneat/reactive-forms';
import { MatSelect } from '@angular/material/select';
import { Category } from '@tremaze/shared/feature/category/types';
import { RemoteCCCategoryDataSource } from '@tremaze/shared/feature/category/data-access';
import { CategoryEditService } from '@tremaze/shared/feature/category/feature/edit';
import { TzPermissionRequest } from '@tremaze/shared/permission/types';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';
import { filterTrue } from '@tremaze/shared/util/rxjs';
import { NotificationService } from '@tremaze/shared/notification';
import { CategoryPrivilegeChecks } from '@tremaze/shared/feature/category/util/privilege-checks';

@Component({
  selector: 'tremaze-category-selector',
  template: `
    <mat-select
      [compareWith]="categoryComparator"
      [formControl]="formControl"
      multiple
    >
      <mat-option
        class="mat-option-hide-checkbox"
        (click)="onClickAddOption($event)"
        *tremazeHasPermission="createPermissionRequest"
      >
        <mat-icon>
          <span class="lnr lnr-plus-circle"></span>
        </mat-icon>
        Kategorie hinzufügen
      </mat-option>
      <mat-option *ngFor="let cat of allCategories$ | async" [value]="cat">
        <div class="cat-option">
          <span
            class="cat-option__Icon lnr lnr-{{ cat.icon }}"
            [style]="{ color: cat.color }"
          ></span>
          <span class="cat-option__Label">{{ cat.name }}</span>
          <ng-container *tremazeHasPermission="getEditPermissionRequest(cat)">
            <button
              *ngIf="showEditButton"
              mat-icon-button
              color="accent"
              matTooltip="Kategorie bearbeiten"
              (click)="onClickEditCategory(cat, $event)"
            >
              <span class="lnr lnr-pencil"></span>
            </button>
          </ng-container>
          <ng-container *tremazeHasPermission="getDeletePermissionRequest(cat)">
            <button
              mat-icon-button
              color="warn"
              matTooltip="Kategorie löschen"
              (click)="onClickDeleteCategory(cat, $event)"
            >
              <span class="lnr lnr-trash2"></span>
            </button>
          </ng-container>
        </div>
      </mat-option>
    </mat-select>
  `,
  styleUrls: ['./selector.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: CategorySelectorComponent,
    },
  ],
})
export class CategorySelectorComponent
  implements
    OnInit,
    OnDestroy,
    DoCheck,
    MatFormFieldControl<Category | Category[]>
{
  static nextId = 0;
  error = false;
  errorState = false;
  controlType?: string;
  autofilled?: boolean;
  stateChanges = new Subject<void>();
  formControl = new FormControl<Category | Category[]>();
  defaultPlaceholder: string;
  @HostBinding()
  id = `institution-selector-${CategorySelectorComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  @Output() clickedAddOption = new EventEmitter();
  allCategories$ = new Subject<Category[]>();
  readonly createPermissionRequest: TzPermissionRequest =
    CategoryPrivilegeChecks.getCreatePrivilegeRequest(
      this.dataSource.categoryType,
    );
  private _allCategories: Category[];
  @ViewChild(MatSelect, { static: true })
  private _input: MatSelect;
  private _reloadSubject = new Subject();
  private subs: Subscription[] = [];

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private dataSource: RemoteCCCategoryDataSource,
    private fm: FocusMonitor,
    private readonly confirmationService: ConfirmationService,
    private readonly notificationService: NotificationService,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private categoryEditService?: CategoryEditService,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  get showEditButton() {
    return !!this.categoryEditService;
  }

  get shouldLabelFloat() {
    return !this.disabled && (!this.empty || this._input.shouldLabelFloat);
  }

  get empty() {
    if (this.value instanceof Array) {
      return !this.value.length;
    }
    return this.value === null || this.value === undefined;
  }

  private _withAddOption = false;

  @Input()
  get withAddOption(): boolean {
    return this._withAddOption;
  }

  set withAddOption(value: boolean) {
    this._withAddOption = coerceBooleanProperty(value);
  }

  private _placeholder: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next(null);
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next(null);
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next(null);
  }

  private _multiple = false;

  @Input()
  get multiple(): boolean {
    return this._multiple;
  }

  set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
    this.stateChanges.next(null);
  }

  private _value: Category | Category[] | null;

  get value() {
    return this._value;
  }

  @Input()
  set value(v: Category | Category[] | null) {
    if (v === undefined) {
      v = null;
    }
    this._value = v;
    this.stateChanges.next(null);
    this.onChangeCallback(this.value);
  }

  private _focused = false;

  get focused(): boolean {
    return this._focused && !this.disabled;
  }

  set focused(value: boolean) {
    this._focused = value;
  }

  getEditPermissionRequest = (category: Category) =>
    CategoryPrivilegeChecks.getEditPrivilegeRequest$(category);

  getDeletePermissionRequest = (row: Category) =>
    CategoryPrivilegeChecks.getDeletePrivilegeRequest$(row);

  categoryComparator = (cat1: Category, cat2: Category) =>
    cat1?.id === cat2?.id;

  reload() {
    this._reloadSubject.next(null);
  }

  onClickAddOption(event: Event) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    this.categoryEditService.create(undefined, (r) => {
      if (r) {
        this._allCategories.push(r);
        this._allCategories = this._allCategories.sort((a, b) =>
          a.name > b.name ? 1 : -1,
        );
        this.allCategories$.next(this._allCategories);
        this.writeValue(r);
      }
    });
  }

  onClickEditCategory(cat: Category, event: Event) {
    event.stopPropagation();
    this.categoryEditService.edit(
      cat.id,
      (r) => {
        if (r) {
          const index = this._allCategories.findIndex((c) => c.id === cat.id);
          if (index >= 0) {
            this._allCategories[index] = r;
            this.allCategories$.next(this._allCategories);
          }
        }
      },
      {
        viewContainerRef: this.viewContainerRef,
      },
    );
  }

  onClickDeleteCategory(cat: Category, event: Event) {
    event.stopPropagation();
    this.confirmationService
      .askUserForConfirmation({ warn: true })
      .pipe(
        map((r) => r?.confirmed),
        filterTrue(),
        switchMap(() => this.dataSource.deleteById(cat.id)),
        tap(() => this.reload()),
        tap(() =>
          this.notificationService.showNotification(
            'Die Kategorie wurde gelöscht',
          ),
        ),
      )
      .subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onContainerClick(event: MouseEvent): void {}

  ngOnInit() {
    this.subs.push(
      this._reloadSubject
        .pipe(
          startWith({}),
          switchMap(() =>
            this.dataSource.getPaginated({
              filter: { sort: 'name', sortDirection: 'asc' },
            }),
          ),
          map((r) => r.content.sort((a, b) => (a.name > b.name ? 1 : -1))),
          tap((r: Category[]) => {
            this._allCategories = r;
            this.allCategories$.next(r);
          }),
        )
        .subscribe(),
    );
    this.placeholder = this.defaultPlaceholder || 'Bitte auswählen';
    this.subs.push(
      this.formControl.valueChanges.subscribe((r) => this.writeValue(r)),
    );
    if (this._input instanceof MatSelect) {
      this.fm
        .monitor(this._input._elementRef.nativeElement, true)
        .subscribe((origin) => {
          this._focused = !!origin;
          this.stateChanges.next(null);
        });
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._reloadSubject.complete();
    this.allCategories$.complete();
    this.subs.forEach((s) => s.unsubscribe());
    this.fm.stopMonitoring(this._input?._elementRef?.nativeElement);
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next(null);
    }
    this.formControl.patchValue(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  writeValue(obj: any): void {
    if (this._value !== obj) {
      this.value = obj;
    }
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  private onTouchedCallback = () => null;

  private onChangeCallback = (r) => null;
}
