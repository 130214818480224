import { Pagination } from '@tremaze/shared/models';
import { Observable, of } from 'rxjs';
import { Fixtures } from './fixtures';
import {
  DataSourceMethodsCreateOptions,
  DataSourceMethodsDeleteOptions,
  DataSourceMethodsEditOptions,
  DataSourceMethodsGetFreshOptions,
  DataSourceMethodsPaginatedOptions,
  DefaultCRUDDataSource,
} from '@tremaze/shared/util-http';

export abstract class CRUDdDataSourceMock<
  T extends object,
> extends DefaultCRUDDataSource<T> {
  create(
    i: T,
    options?: DataSourceMethodsCreateOptions<T> | undefined,
  ): Observable<T> {
    return of(this.fixtures.generateByIndex(0));
  }

  getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions,
  ): Observable<T> {
    return of(this.fixtures.generateByIndex(0));
  }

  edit(
    i: T,
    options?: DataSourceMethodsEditOptions<T> | undefined,
  ): Observable<T> {
    return of(this.fixtures.generateByIndex(0));
  }

  deleteById(
    id: string,
    options?: DataSourceMethodsDeleteOptions,
  ): Observable<boolean> {
    return of(true);
  }

  getPaginated(
    options?: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<T>> {
    return of(this.fixtures.generatePage(options ?? {}));
  }

  override getManyFreshByIds(
    ids: string[],
    options?: DataSourceMethodsGetFreshOptions<T> | undefined,
  ): Observable<T[]> {
    return of(ids.map(() => this.fixtures.generateByIndex(0)));
  }

  abstract get fixtures(): Fixtures<T>;
}
