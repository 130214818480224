type AddressField = 'street' | 'city' | 'country' | 'zip' | 'addition';

export type TremazeModuleName =
  | 'CUSTOM_FORM'
  | 'WORKFLOW'
  | 'DASHBOARD'
  | 'GOAL'
  | 'SPECIALIZATION'
  | 'EVENT_REMINDER'
  | 'ADDITIONAL_INFO'
  | 'ADDITIONAL_INFO_2'
  | 'FAKE_ACCOUNT'
  | 'EVENT_SCHEDULE'
  | 'POSTBOX'
  | 'FAMILY'
  | 'CONTACT_POINT'
  | 'CHAT'
  | 'DIVISION'
  | 'EVENT_CALENDAR_OLD'
  | 'INFORMATION_DIVISION'
  | 'WEBSITE'
  | 'EVENT_PRESETS'
  | 'APPROVAL'
  | 'VIDEO_MEETING'
  | 'VIVENDI_NG_APPROVAL'
  | 'SIMULTANEOUS_EDITING_OF_FILES'
  | 'CASE_MANAGEMENT'

export interface BaseTenantConfig {
  enableProfileImages: boolean;
  enableRegistrationInfo: boolean;
  addressFieldsRequired: Set<AddressField>;
  disabledFeatures: Set<TremazeModuleName>;
  defaultForceDocumentationPeriodForEvents: boolean;
  tremazeCustomer: boolean;
}

export interface EventConfig {
  publicEvents?: 'DEFAULT_ON' | 'DEFAULT_OFF' | 'DISABLED';
}

export interface InformationConfig {
  publicInformation?: 'DEFAULT_ON' | 'DEFAULT_OFF' | 'DISABLED';
}

export interface GoalsConfig {
  isGoalsEnabled: boolean;
}

export type FullTenantConfig = BaseTenantConfig &
  EventConfig &
  InformationConfig &
  GoalsConfig;
