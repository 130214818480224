import {
  CustomForm,
  CustomFormSubmission,
  CustomFormVersion,
} from '@tremaze/shared/feature/custom-forms/types';
import { Observable, of } from 'rxjs';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { Injectable } from '@angular/core';
import { CustomFormFillOutDataSource } from '@tremaze/shared/feature/custom-forms/feature/fill-out/data-access';
import { CustomFormFixtures, FileStorageFixtures } from '@tremaze/fixtures';

@Injectable()
export class MockCustomFormFillOutDataSource extends CustomFormFillOutDataSource {
  getFormVersion(id: string): Observable<CustomFormVersion> {
    return of(CustomFormFixtures.generateCustomFormVersion(id));
  }

  getForm(id: string): Observable<CustomForm> {
    return of(CustomFormFixtures.generateCustomForm(id));
  }

  getSubmission(id: string): Observable<CustomFormSubmission> {
    return of(CustomFormFixtures.generateCustomFormSubmission(id));
  }

  uploadFiles(
    files: FileList,
    config?: {
      onProgress?: (progress: number) => void;
    },
  ): Observable<FileStorage[]> {
    return of(
      Array.from(files).map((file, index) =>
        FileStorageFixtures.generateFileStorage(index),
      ),
    );
  }
}

export const provideMockCustomFormFillOutDataSource = () => ({
  provide: CustomFormFillOutDataSource,
  useClass: MockCustomFormFillOutDataSource,
});
