import { Fixtures } from '../fixtures';
import { ExternalResource } from '@tremaze/external-resource-types';

export class ExternalResourceFixtures extends Fixtures<ExternalResource> {
  override generateByIndex(index: number): ExternalResource {
    return new ExternalResource(
      `external-resource-${index}`,
      `ExternalResource ${index}`,
      `https://example.com/${index}`,
      'ALL',
    );
  }
}
