import { PageGenerator } from '../helpers';
import { TextSnippet } from '@tremaze/text-snippets-types';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';

class TextSnippetsPageGenerator extends PageGenerator<TextSnippet> {
  generateByIndex(index: number): TextSnippet {
    return {
      id: `text-snippet-${index}`,
      name: `TextSnippet ${index}`,
      text: `TextSnippet ${index} text`,
    };
  }
}

export abstract class TextSnippetFixtures {
  static generatePage(options: DataSourceMethodsPaginatedOptions) {
    return new TextSnippetsPageGenerator().generatePage(options);
  }

  static readonly textSnippet1: TextSnippet = {
    id: 'text-snippet-1',
    name: 'TextSnippet 1',
    text: 'TextSnippet 1 text',
  };

  static readonly textSnippet2: TextSnippet = {
    id: 'text-snippet-2',
    name: 'TextSnippet 2',
    text: 'TextSnippet 2 text',
  };
}
