import { Injectable } from '@angular/core';
import { Pagination } from '@tremaze/shared/models';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { TextSnippet } from '@tremaze/text-snippets-types';
import { Observable, of } from 'rxjs';
import { TextSnippetDataSource } from './text-snippets-data-source';
import { TextSnippetFixtures } from '@tremaze/fixtures';

@Injectable()
export class TextSnippetsDataSourceMock implements TextSnippetDataSource {
  getTextSnippet(id: string): Observable<TextSnippet> {
    return of(TextSnippetFixtures.textSnippet1);
  }

  getTextSnippetsPageForInstitution(
    instId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<TextSnippet>> {
    return of(TextSnippetFixtures.generatePage(options));
  }

  getTextSnippetsPage(
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<TextSnippet>> {
    return of(TextSnippetFixtures.generatePage(options));
  }

  deleteTextSnippet(id: string): Observable<void> {
    return of(undefined);
  }

  createTextSnippetForInstitution(
    instId: string,
    textSnippet: Required<Pick<TextSnippet, 'name' | 'text'>>,
  ): Observable<TextSnippet> {
    return of(TextSnippetFixtures.textSnippet2);
  }

  updateTextSnippet(
    id: string,
    textSnippet: Required<Pick<TextSnippet, 'name' | 'text'>>,
  ): Observable<TextSnippet> {
    return of(TextSnippetFixtures.textSnippet1);
  }
}

export const provideTextSnippetsDataSourceMock = () => ({
  provide: TextSnippetDataSource,
  useClass: TextSnippetsDataSourceMock,
});
